<template>
  <v-app id="app">
    <Navbar v-if="showNavbar" />
    <router-view />
    <FloatingUploadWindow v-if="isFloatingUploadWindowVisible" />
    <Loader v-if="isLoaderVisible" />
    <transition name="slide" v-for="(message, key) in errorMessages" :key="key">
      <ErrorMessage
        :errorMessage="message"
        :snackbar="!!message"
        :index="key"
      />
    </transition>
    <ReportError v-if="showNavbar" />
    <v-snackbar
      color="success"
      v-model="showSuccessSnackbar"
      :timeout="successMessageTimeout"
      top
      min-width="fit-content"
    >
      {{ successMessage }}
    </v-snackbar>
    <DownloadDialog
      ref="downloadDialogRef"
      :isDownloadDialogVisible="isDownloadDialogVisible"
      :showDownloadDialogVisible="showDownloadDialogVisible"
      :videoLink="exportClip.videoLink"
      :audioLink="exportClip.audioLink"
      :videoName="exportClip.clipName"
      :videoUuid="$route.params.uuid"
      :exportClipAction="exportClipAction"
      :fetchExportedClipAction="fetchExportedClipAction"
      :exportingStatus="exportedClip ? exportedClip.status : ''"
      :exportingDate="exportedClip ? exportedClip.started_at : ''"
      :lastExportedBurnSubtitle="
        exportedClip ? exportedClip.burn_subtitles : false"
      :clip="exportClip.clip"
      :onUpdateExportedClip="onUpdateExportedClip"
    />
    <DownloadFloatingWindow v-if="allExports.length"/>
  </v-app>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import Navbar from "./shared/Navbar.vue";
import FloatingUploadWindow from "./components/Videos/FloatingUploadWindow.vue";
import Loader from "./shared/Loader.vue";
import ErrorMessage from "./components/ErrorMessage";
import ReportError from "./components/ReportError/ReportError.vue"
import DownloadDialog from "./components/Videos/DownloadDialog.vue"
import DownloadFloatingWindow from "./components/Videos/DownloadFloatingWindow.vue";
import types from "./store/types";
import {
  getUserRefreshTokenCookie,
  getAccessTokenFromCookie,
} from "./utils/Cookies";

export default {
  components: {
    Navbar,
    FloatingUploadWindow,
    Loader,
    ErrorMessage,
    ReportError,
    DownloadDialog,
    DownloadFloatingWindow
  },
  data(){
    return{
      exportedClip:null
    }
  },
  methods: {
    ...mapMutations({
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
      setRefreshToken: types.user.mutations.SET_REFRESH_TOKEN,
      showFloatingUploadWindow:
        types.project.mutations.SET_IS_FLOATING_UPLOAD_WINDOW_VISIBLE,
      updateExportState:types.download.mutations.SET_EXPORT_CLIP_STATE,
      setDownloadDialogVisibility:types.app.mutations.SET_DOWNLOAD_DIALOG_VISIBILITY
    }),
    ...mapActions({
      fetchUserProfile: types.user.actions.USER_PROFILE,
      fetchExportedClip: types.project.actions.FETCH_EXPORTED_CLIP,
      exportOfClip: types.project.actions.EXPORT_CLIP,
      setExportedClip: types.download.actions.SET_EXPORT_CLIP
    }),
    isDemo() {
      const currentRoutePath = this.$route.path;
      return currentRoutePath.includes('/demo');
    },
    async exportClipAction(burnSubtitles) {
      const { clipUuid } = this.exportClip;
      const videoDim = document.getElementById("fake-video-layer");
      if (clipUuid)
        try {
          const payload = { clipUuid: clipUuid, burn_subtitles: burnSubtitles, highlight_subtitle_word: this.clipStyle.caption_highlight, preview_height: videoDim.clientHeight, preview_width: videoDim.clientWidth, auto_speaker_crop:this.autoCropping?this.autoCropping.dimension:'' };
          const response = await this.exportOfClip(payload);

          this.exportedClip = response;
        } catch (err) {
          // handled in store
        }
    },
    async fetchExportedClipAction() {
      if (this.exportClip.clipUuid) {
        try {
          const payload = { clipUuid: this.$route.params.clipUuid };
          const response = await this.fetchExportedClip(payload);

          this.setClipDownloadLinks(
            response?.results[0] || {},
            this.exportClip.clipName
          );
          
        } catch (err) {
          // handled in store
        }
      }
    },
    showDownloadDialogVisible() {
      this.setDownloadDialogVisibility(false)
    },
    setClipDownloadLinks(clip, clipName) {
      this.videoName = clipName;
      this.videoLink = clip.clip_file;
      this.audioLink = clip.clip_audio_file;
      this.exportedClip = clip;
    },
    downloadFileAfterExporting() {
      const downloadDialogRef = this.$refs["downloadDialogRef"];
      if (downloadDialogRef) {
        const extension = this.exportClip.exportingExtension;
        if (extension) {
          // this.getchCurrentVideo();
          const link = extension == "mp4" ? this.videoLink : this.audioLink;
          downloadDialogRef.downloadFile(link, extension);
        }
      }
    },
    onUpdateExportedClip(clip){
      this.exportedClip = clip
    }
  },
  computed: {
    ...mapState({
      errorMessages: (state) => state.app.errorMessages,
      isFloatingUploadWindowVisible: (state) =>
        state.project.isFloatingUploadWindowVisible,
      uploadingVideos: (state) => state.project.uploadingVideos,
      isLoaderVisible: (state) => state.app.isLoaderVisible,
      user: (state) => state.user.user,
      refreshToken: (state) => state.user.refreshToken,
      successMessage: (state) => state.app.successMessage,
      successMessageTimeout: (state) => state.app.successMessageTimeout,
      exportClip:(state)=>  state.download.export,
      isDownloadDialogVisible: (state) => state.app.isDownloadDialogVisible,
      clipStyle:(state)=>state.project.clipStyle,
      allExports:(state)=>state.download.allExports,
      autoCropping: (state) => state.autoCrop.autoCropping
    }),
    showNavbar() {
      const { name } = this.$route;
      return (
        name !== "SignIn" &&
        name !== "Verify" &&
        name !== "SignUp" &&
        name !== "ForgotPassword" &&
        name !== "ResetPassword" &&
        name !== "DemoEditClipForVideo" &&
        name !== "NotFound" &&
        !this.$route.path.includes("/demo")
      );
    },
    showSuccessSnackbar: {
      get() {
        return !!this.successMessage;
      },
      set() {
        this.setSuccessMessage("");
      },
    },
  },
  watch: {
    uploadingVideos() {
      if (this.uploadingVideos.length === 0)
        this.showFloatingUploadWindow(false);
    },
    isDownloadDialogVisible(){
      if(this.isDownloadDialogVisible){
        this.fetchExportedClipAction()
      }
      
    }
  },
  beforeMount() {
    this.setErrorMessage([]);
    this.setSuccessMessage("");
  },
  async mounted() {
    const localAccessToken = getAccessTokenFromCookie();
    const localRefreshToken = getUserRefreshTokenCookie();
    if (!localAccessToken) return;
    try {
      await this.fetchUserProfile(localAccessToken);
      if (localRefreshToken) this.setRefreshToken(localRefreshToken);
    } catch (error) {
      // handled in store
    }
  }
};
</script>

<style lang="scss">
@import "./assets/styles/app.scss";
</style>
