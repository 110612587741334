<template>
  <VueDragResize
    :isActive="true"
    :isResizable="false"
    :parentLimitation="true"
    :isDraggable="isEditPage"
    w="auto"
    h="auto"
    :x="left"
    :y="top"
    :parentW="parentWidth"
    :parentH="parentHeight"
    class="title-wrapper d-flex justify-content-center"
    @dragstop="changeStatus"
    @clicked="onTitleClicked"
    @dragging="(e) => setVerticalLineVisibility(true, e)"
    id="title-text"
    :style="{
      fontSize: title.font_size ? title.font_size + 'px' : '20px',
      color: title.color ? title.color : 'white',
      fontWeight: 'bolder',
      backgroundColor: title.background_color
        ? title.background_color
        : 'transparent',
      visibility: title.uuid && isFakeVideoLayerFilled?'visible':'hidden' 
    }"
    :z="1"
  >
    <p
      class="title-wrapper__text"
      :class="['title-wrapper__text', { enabled: isEditPage }]"
    >
      <span
        class="title-wrapper__text__content"
        :style="{
          fontFamily: title.font + ' !important',
        }"
        >{{ title.text }}
      </span>
    </p>
    <v-icon
      v-if="isEditPage"
      class="title-wrapper__delete"
      size="18"
      @click="deleteTitle"
      >mdi-close
    </v-icon>
  </VueDragResize>
</template>

<script>
import VueDragResize from "vue-drag-resize";
import { mapActions, mapMutations, mapState } from "vuex";
import calculateLayerPosition from "../../utils/LayerPosition";
import types from "../../store/types";

export default {
  props: {
    title: Object,
    isEditPage: Boolean,
    resizeDimension: String,
    isCrop: Boolean,
    fetchCurrentClip: Function,
    resizeCalled:Boolean,
    isFakeVideoLayerFilled:Boolean,
    showPlayer:Boolean
  },
  data() {
    return {
      isClick: false,
      top: 0,
      left: 0,
      isDeleting: false,
      isSetPosition: false,
      parentWidth: 0,
      parentHeight: 0,
    };
  },
  components: {
    VueDragResize,
  },
  computed: {
    ...mapState({
      selectedTitleUuid: (state) => state.project.selectedTitleUuid,
      clip: (state) => state.project.clip,
    }),
  },
  created() {
    this.setLayerPosition();
  },
  watch: {
    title() {
      this.setLayerPosition();
    },
    resizeDimension() {
      this.setLayerPosition();
    },
    isCrop() {
      this.setLayerPosition();
    },
    resizeCalled() {
      if(this.resizeCalled) {
        const videoDim = document.getElementById("fake-video-layer");
        this.setPosition(
            this.left / videoDim.clientWidth,
            this.top / videoDim.clientHeight
          );
      }
    }
  },
  methods: {
    ...mapActions({
      updateTextLayer: types.project.actions.UPDATE_TEXT_LAYER,
      deleteTextLayerAction: types.project.actions.DELETE_TEXT_LAYER,
    }),
    ...mapMutations({
      setSelectedTitleUuid: types.project.mutations.SET_SELECTED_TITLE_UUID,
      updateVideoTitleInStore: types.project.mutations.UPDATE_TEXT_LAYER,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
    }),
    setVerticalLineVisibility(visibility, newRect) {
      const verticalLine = document.getElementById("center-vertical-line");

      if (!visibility) {
        verticalLine.style.display = "none";
      } else if (visibility) {
        const parentCenter = this.parentWidth / 2;
        const itemWidth = newRect.width;
        const itemCenter = newRect.left + itemWidth / 2;

        if (parentCenter + 1 >= itemCenter && parentCenter - 1 <= itemCenter) {
          verticalLine.style.display = "block";
        } else {
          verticalLine.style.display = "none";
        }
      }
    },
    setLayerPosition() {
      this.isSetPosition = true;
      const { parentWidth, parentHeight, top, left } = calculateLayerPosition(
        this.title
      );
      this.parentWidth = parentWidth;
      this.parentHeight = parentHeight;
      this.top = top;
      this.left = left < 0 ? 0 : left;
      setTimeout(() => {
        this.isSetPosition = false;
      }, 200);
    },
    onTitleClicked() {
      this.setSelectedTitleUuid(this.title.uuid);
    },
    changeStatus(newRect) {
      this.setVerticalLineVisibility(false);
      setTimeout(() => {
        const videoDim = document.getElementById("fake-video-layer");

        if (!this.isDeleting && !this.isSetPosition) {
          this.setPosition(
            newRect.left / videoDim.clientWidth,
            newRect.top / videoDim.clientHeight
          );
        }
      }, 0);
    },
    setPosition: async function (x, y) {
      const { clientWidth: titleDomWidth, clientHeight: titleDomHeight } =
          document.getElementById(`${this.title.uuid}`);
        const {
          clientWidth: VideoPlayerWidth,
          clientHeight: VideoPlayerHeight,
        } = document.getElementById("fake-video-layer");
        const { width, height } = this.clip;
        const boxWidth = Math.round((titleDomWidth / VideoPlayerWidth) * width);
        const boxHeight = Math.round(
          (titleDomHeight / VideoPlayerHeight) * height
        );
      const payload = {
        ...this.title,
        box_width:boxWidth,
        box_height:boxHeight,
        x_pos_decimal: x < 0 ? 0 : x,
        y_pos_decimal: y < 0 ? 0 : y,
        y_pos_string: null,
        x_pos_string: null,
      };

      this.updateVideoTitleInStore(payload);

      await this.updateTextLayer(payload);

      this.fetchCurrentClip();
    },
    deleteTitle() {
      this.isDeleting = true;
      this.deleteTextLayerAction(this.title.uuid).then(() => {
        this.isDeleting = false;
        this.fetchCurrentClip();
      });
      if (this.title.uuid == this.selectedTitleUuid) {
        this.setSelectedTitleUuid("");
      }
      this.setSuccessMessage("Text deleted successfully");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/videos/titleText.scss";
</style>
